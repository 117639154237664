import React from 'react';
import './ExperienceStyling.css';
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from 'react-i18next';

const experiences = [
  {
    id: 1,
    title: "Software Developer",
    company: "Company A",
    duration: "Jan 2020 - Present",
    description: "Developed and maintained code for in-house and client websites primarily using HTML, CSS, Sass, JavaScript, and jQuery.",
    technologies: ["Jira"]
  },
  {
    id: 2,
    title: "Intern",
    company: "Company B",
    duration: "May 2018 - Dec 2019",
    description: "Assisted in the development and maintenance of company websites, including bug fixes, feature additions, and performance optimizations.",
    technologies: ["JavaScript", "PHP", "HTML", "CSS"]
  },
];

const ExperienceNode = ({ title, company, duration, description, technologies, animation }) => {
  const { t } = useTranslation();

  return (
    <div className="experienceNode">
      <ScrollAnimation animateIn={animation}>
        <h3>{t(title)} @ {t(company)}</h3>
        <p>{t(duration)}</p>
        <p>{t(description)}</p>
        <div className="technologies">
          <p><strong>{t(`experience.1.technologiesUsed`)}</strong></p>
          <div className="techButtons">
            {technologies.map((tech, index) => (
              <button key={index} className="techButton">{t(tech)}</button>
            ))}
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
};

const Experience = () => {
  const { t } = useTranslation();

  return (
    <div className="experienceTree">
      <div className='SectionTitle'>{t('experience.header')}</div>
      {experiences.map((exp, index) => (
        <ExperienceNode
          key={exp.id}
          animation={index % 2 === 0 ? 'fadeInLeft' : 'fadeInRight'}
          title={t(`experience.${exp.id}.title`)}
          company={t(`experience.${exp.id}.company`)}
          duration={t(`experience.${exp.id}.duration`)}
          description={t(`experience.${exp.id}.description`)}
          technologies={exp.technologies}
        />
      ))}
    </div>
  );
};

export default Experience;
